import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["game"],
  "status": ["completed"],
  "title": "The Game",
  "subtitle": "Random generated labyrinth racing game.",
  "description": "",
  "years": ["2014"],
  "institutions": ["aau-cph"],
  "location": "cph",
  "keywords": {
    "topics": ["game", "creative code"],
    "tools": ["processing"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer and Developer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # The Game */
    }
    <h2>{`Description`}</h2>
    <p>{`The final project of the programming course were to make a game. This is what ignited my curiousness for coding, which eventually led me to chance to DTU.`}</p>
    <h2>{`Media`}</h2>
    <ul>
      <li parentName="ul">{`Github`}</li>
      <li parentName="ul">{`Screenshots`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      